
import { Options, Vue } from 'vue-class-component';
import EnvelopeSVG from '@/components/EnvelopeSVG.vue';
import { contact } from '@/Lib';

@Options({
    components: {
        EnvelopeSVG,
    },
})
export default class ContactSection extends Vue {
    contact = contact;
}
