
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class AboutSection extends Vue {
    vimrc_url = "https://github.com/hahdookin/VimConfig";
    mounted() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('square-animation')
                }
            })
        });
        observer.observe(document.getElementById('headshot') as Element);
    }
}
