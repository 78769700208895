
import { Options, Vue } from 'vue-class-component';

import ComputerRender from '@/components/ComputerRender.vue';
import CubeSVG from '@/components/CubeSVG.vue';

import { socials } from '@/Lib';

@Options({
    components: {
        ComputerRender,
        CubeSVG,
    },
})
export default class App extends Vue {
    curRoute = "";
    github = socials.github;
    iesmach = "https://wellstat.io";
    njit = "https://njit.edu";
    mainCubeVisible = false;
}
