
import { Options, Vue } from 'vue-class-component';
import ComputerRender from '@/components/ComputerRender.vue';

@Options({
    components: {
        ComputerRender
    },
})
export default class HomeView extends Vue {}
