
import { Options, Vue } from 'vue-class-component';
import PopupMenu from '@/components/PopupMenu.vue';

@Options({
    components: {
        PopupMenu,
    },
})
export default class NavBar extends Vue {
    /* name = ""; */
    /* publicPath = process.env.BASE_URL; */
    /* @Watch('$route', { immediate: true, deep: true }) */
    /* onUrlChange(newVal: any) { */
    /*     this.name = newVal.name; */
    /* } */
}
