import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_IntroSection = _resolveComponent("IntroSection")!
  const _component_ArrowSVG = _resolveComponent("ArrowSVG")!
  const _component_AboutSection = _resolveComponent("AboutSection")!
  const _component_PortfolioSection = _resolveComponent("PortfolioSection")!
  const _component_ContactSection = _resolveComponent("ContactSection")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createVNode(_component_IntroSection),
    _createVNode(_component_ArrowSVG),
    _createVNode(_component_AboutSection),
    _createVNode(_component_PortfolioSection),
    _createVNode(_component_ContactSection),
    _createVNode(_component_FooterSection)
  ], 64))
}