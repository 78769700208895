
import { Options, Vue } from 'vue-class-component';
import CubestormSVG from '@/components/CubestormSVG.vue';
import { portfolio, PortfolioEntry } from '@/Lib';

interface MainPortfolioEntry {
    entry: PortfolioEntry;
    genre: string;
    imgURL: string;
}
@Options({
    components: {
        CubestormSVG,
    },
})
export default class PortfolioSection extends Vue {
    portfolio = portfolio;
    main_sellers: MainPortfolioEntry[] = [];
    mounted() {
        this.main_sellers = [
            {
                entry: portfolio.find((e) => e.title === 'miniterm.vim') as PortfolioEntry,
                genre: 'VIM PLUGIN',
                imgURL: require('@/assets/img/portfolio/miniterm.gif'),
            },
            {
                entry: portfolio.find((e) => e.title === 'CG Museum') as PortfolioEntry,
                genre: '3D GRAPHICS',
                imgURL: require('@/assets/img/portfolio/cgmuseum.png'),
            },
            {
                entry: portfolio.find((e) => e.title === 'Browser REPL') as PortfolioEntry,
                genre: 'WEB DEVELOPMENT',
                imgURL: require('@/assets/img/portfolio/browser_repl.gif'),
            },
        ];
    }
}
