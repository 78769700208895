
import { Options, Vue } from 'vue-class-component';
import { socials, contact } from '@/Lib';

@Options({
    components: {},
})
export default class FooterSection extends Vue {
    socials = socials;
    contact = contact;
    publicPath = process.env.BASE_URL;
}
